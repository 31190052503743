import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import SideMenu from "../../components/SideMenu";
import { useNavigate } from "react-router-dom";
import webService from "../../services/web.service";
import { useTranslation } from "react-i18next";
import ProductsGrid from "./ProductsGrid";
import { STRUCTURE_NAME } from "../../config/backendConfig";

function Shop({ uuid, color }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const { t } = useTranslation("common");

  let navigate = useNavigate();

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  React.useEffect(() => {
    document.title = t("titles.shop", {
      structure_name: STRUCTURE_NAME,
    });
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };
  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("shop.title")}
        </div>
      </div>

      <div className="w-full lg:max-w-7xl mx-auto py-10 px-10 lg:px-0">
        <ProductsGrid config={config} uuid={uuid} />
      </div>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy />
      <ButtonBar color={config.color} />
    </>
  );
}

export default Shop;
