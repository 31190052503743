import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { Button, Result } from "antd";
import { toast } from "react-hot-toast";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import webService from "../../services/web.service";
import useStateCallback from "../../components/useStateCallback";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ContactForm({ uuid }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSent, setIsSent] = useStateCallback(false);
  const [state, setState] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
    captcha: "",
    privacy: false,
  });
  const { t } = useTranslation("common");

  useEffect(() => {
    loadCaptchaEnginge(8);
  }, []);

  useEffect(() => {
    var isDis = false;
    if (!state.name) isDis = true;
    if (!state.surname) isDis = true;
    if (!state.email) isDis = true;
    if (!state.phone) isDis = true;
    if (!state.message) isDis = true;
    if (!state.captcha) isDis = true;
    if (!state.privacy) isDis = true;
    setIsDisabled(isDis);
  }, [state]);

  useEffect(() => {
    if (isSent) {
      const timer = setTimeout(() => {
        setIsSent(false, () => {
          loadCaptchaEnginge(8);
        });
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isSent]);

  const SendContactForm = async () => {
    if (validateCaptcha(state.captcha) == true) {
      setState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        message: "",
        captcha: "",
        privacy: false,
      });

      webService
        .newMessageContact({
          ...state,
          uuid: uuid,
        })
        .then(
          (result) => {
            const status = result.status;
            if (status === 200) {
              setIsSent(true);
            } else toast.error(t("contacts.error"));
          },
          (error) => {
            toast.error(t("contacts.error"));
          }
        );
    } else {
      toast.error(t("contacts.errorCaptcha"));
    }
  };

  return (
    <>
      {!isSent ? (
        <div className="isolate bg-gray-100 px-6 lg:px-8">
          <div
            className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
            aria-hidden="true"
          >
            <div
              className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("contacts.subtitle")}
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              {t("contacts.description")}
            </p>
          </div>
          <form
            action="#"
            method="POST"
            className="mx-auto mt-16 max-w-xl sm:mt-20"
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("contacts.form.name")}
                </label>
                <div className="mt-2.5">
                  <input
                    value={state.name}
                    onChange={(e) =>
                      setState({ ...state, name: e.target.value })
                    }
                    type="text"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("contacts.form.surname")}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    value={state.surname}
                    onChange={(e) =>
                      setState({ ...state, surname: e.target.value })
                    }
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("contacts.form.email")}
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    value={state.email}
                    onChange={(e) =>
                      setState({ ...state, email: e.target.value })
                    }
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("contacts.form.phone")}
                </label>
                <div className="relative mt-2.5">
                  <input
                    type="tel"
                    value={state.phone}
                    onChange={(e) =>
                      setState({ ...state, phone: e.target.value })
                    }
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("contacts.form.message")}
                </label>
                <div className="mt-2.5">
                  <textarea
                    value={state.message}
                    onChange={(e) =>
                      setState({ ...state, message: e.target.value })
                    }
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t("contacts.form.verify")}
                </label>
                <div className="mt-2.5">
                  <LoadCanvasTemplate
                    reloadText={t("contacts.reloadCaptcha")}
                  />

                  <input
                    type="text"
                    value={state.captcha}
                    onChange={(e) =>
                      setState({ ...state, captcha: e.target.value })
                    }
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                <div className="flex h-6 items-center">
                  <Switch
                    checked={state.privacy}
                    onChange={(e) => setState({ ...state, privacy: e })}
                    className={classNames(
                      state.privacy ? "bg-indigo-600" : "bg-gray-200",
                      "flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                  >
                    <span className="sr-only">
                      {t("contacts.form.acceptPolicies")}
                    </span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        state.privacy ? "translate-x-3.5" : "translate-x-0",
                        "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </div>
                <Switch.Label className="text-sm leading-6 text-gray-600">
                  {t("contacts.form.acceptPolicies2")}
                  <a href="#" className="font-semibold text-indigo-600">
                    privacy&nbsp;policy
                  </a>
                  .
                </Switch.Label>
              </Switch.Group>
            </div>
            <div className="mt-10">
              <Button
                disabled={isDisabled}
                onClick={SendContactForm}
                className={`block w-full rounded-md 
            px-3.5 py-2.5 text-center text-sm 
            font-semibold  shadow-sm  
            focus-visible:!outline focus-visible:outline-2 focus-visible:outline-offset-2 
            
            ${
              isDisabled
                ? "!bg-gray-100 !text-gray-400"
                : "!bg-indigo-600 !text-white hover:!bg-indigo-500 focus-visible:!outline-indigo-600 "
            }`}
              >
                {t("contacts.form.send")}
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <Result
          status="success"
          title={t("contacts.successTitle")}
          subTitle={t("contacts.successSubtitle")}
        />
      )}
    </>
  );
}
