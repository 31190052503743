import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import SideMenu from "../../components/SideMenu";
import { useNavigate } from "react-router-dom";
import webService from "../../services/web.service";
import { useTranslation } from "react-i18next";
// import Advisor from "../../components/Advisor";
import { tcolors } from "../../config/Colors";
import { css } from "glamor";
// import CTA from "../../components/CTA";
import { STRUCTURE_NAME } from "../../config/backendConfig";
import LoadingIndicator from "../../components/LoadingIndicator";

function WhereAreWe({ uuid, color }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [wherearewe, setWhereAreWe] = React.useState({});
  const [config, setConfig] = React.useState({});
  const { t } = useTranslation("common");

  let navigate = useNavigate();
  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  React.useEffect(() => {
    document.title = t("titles.where-are-we", {
      structure_name: STRUCTURE_NAME,
    });
    if (uuid) fetchWhereAreWe();
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
        }
      },
      (error) => {}
    );
  };

  const fetchWhereAreWe = async () => {
    webService.getWhereAreWe(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setWhereAreWe(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };
  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("whereAreWe.title")}
        </div>
      </div>

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="w-full lg:max-w-7xl mx-auto py-20">
            <div className="flex flex-col gap-3">
              <h1 className="text-3xl font-semibold mb-5">
                {wherearewe.title_obj.text}
              </h1>
              <p>{wherearewe.description_obj.text}</p>
              <div
                className="h-[3px] w-[60px] rounded-lg mt-7"
                {...css({
                  backgroundColor: tcolors[config.color + "-300"],
                })}
              />
            </div>
          </div>
        </>
      )}

      <iframe
        src={wherearewe.map_url}
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy />
      <ButtonBar color={config.color} />
    </>
  );
}

export default WhereAreWe;
