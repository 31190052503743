import React from "react";
import BookingEngineContainer from "./BookingEngineContainer";
import ImageCarousel from "./ImageCarousel";
import VideoHome from "./VideoHome";
import webService from "../services/web.service";

function Banner({ uuid, useVideo, color, logo }) {
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    if (uuid) fetchBanner();
  }, []);

  const fetchBanner = async () => {
    webService.getBanners(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setImages(result.data);
      },
      (error) => {}
    );
  };

  return (
    <div
      className="
        h-[300px] 
        sm:h-[350px] 
        md:h-[400px]
        lg:h-[500px] 
        xl:h-[600px]
        2xl:h-[700px]
        w-full
        relative"
    >
      <BookingEngineContainer uuid={uuid} color={color} logo={logo} />
      {useVideo ? (
        <>
          <div className="hidden md:flex w-full h-full">
            <VideoHome />
          </div>
          <div className="flex md:hidden w-full h-full">
            <ImageCarousel images={images} />
          </div>
        </>
      ) : (
        <ImageCarousel images={images} />
      )}
    </div>
  );
}

export default Banner;
