import React from "react";
import { Spin } from "antd";
import { Button, Modal } from "antd";
import { useState } from "react";

function LoadingModal(props) {
  return (
    <>
      <Modal footer={null} open={props.open} closable={false} centered>
        <div className="flex flex-col items-center justify-center gap-5">
          <h1 className="text-xl font-semibold">{props.title}</h1>
          <Spin />
        </div>
      </Modal>
    </>
  );
}

export default LoadingModal;
