import React from "react";
import Advisor from "../../components/Advisor";
import Banner from "../../components/Banner";
import BestChoice from "../../components/BestChoice";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import { ImageCDN } from "../../components/ImageCDN";
import Newsletter from "../../components/Newsletter";
import RoomsPreview from "../../components/RoomsPreview";
import SideMenu from "../../components/SideMenu";
import StructurePreview from "../../components/StructurePreview";
import StructureServices from "../../components/StructureServices";
import webService from "../../services/web.service";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useNavigate } from "react-router-dom";

function Home({ uuid }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});

  let navigate = useNavigate();

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Banner
            uuid={uuid}
            useVideo={config.urlVideo !== "" ? true : false}
            color={config.color}
            logo={config.white_logo}
          />
          <SideMenu uuid={uuid} color={config.color} />

          <main className=" bg-gray-100">
            <div className="mx-5 sm:mx-3 lg:mx-auto max-w-7xl pb-5">
              <BestChoice uuid={uuid} color={config.color} />
              <Newsletter uuid={uuid} color={config.color} />
            </div>
          </main>

          <section className=" bg-white flex items-center mt-14">
            <StructurePreview uuid={uuid} />
          </section>

          {/* <section className="bg-white flex items-center justify-center">
            <StructureServices uuid={uuid} />
          </section> */}

          <Advisor id_advisor="1" color={config.color} />

          <section className="bg-white flex items-center justify-center">
            <RoomsPreview uuid={uuid} color={config.color} />
          </section>

          <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
          <FooterMadeBy uuid={uuid} color={config.color} />
          <ButtonBar uuid={uuid} color={config.color} />
        </>
      )}
    </>
  );
}

export default Home;
