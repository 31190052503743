import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import SideMenu from "../../components/SideMenu";
import webService from "../../services/web.service";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

function Surroundings({ uuid }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [config, setConfig] = React.useState({});
  const [surroundings, setSurroundings] = React.useState({});
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setConfig(result.data);
        fetchSurroundings();
      },
      (error) => {}
    );
  };
  const fetchSurroundings = async () => {
    webService.getSurroundings(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setSurroundings(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };
  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("surroundings.title")}
        </div>
      </div>

      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center py-4">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div className="max-w-7xl mx-auto my-24 px-10 lg:px-0">
            <section className="text-center">
              {surroundings.descr1_obj.text}
            </section>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-32">
              <div className="flex flex-row gap-3">
                <img
                  src={surroundings.image1}
                  loading="lazy"
                  alt="surroundings-1"
                  className="w-1/2"
                />
                <div className="flex flex-col gap-3 w-1/2">
                  <img
                    src={surroundings.image2}
                    loading="lazy"
                    alt="surroundings-1"
                    className="object-cover"
                  />
                  <img
                    src={surroundings.image3}
                    loading="lazy"
                    alt="surroundings-1"
                    className="flex-1 object-cover"
                  />
                </div>
              </div>
              <div>
                <h1 className="uppercase text-4xl text-wider text-slate-700 text-center font-semibold">
                  {t("surroundings.why_come_to_us")}
                </h1>
                <div className="mt-10 text-center px-6">
                  {surroundings.descr2_obj.text}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy uuid={uuid} color={config.color} />
      <ButtonBar uuid={uuid} color={config.color} />
    </>
  );
}

export default Surroundings;
