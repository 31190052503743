import React, { useState } from "react";
import { Divider } from "antd";
import Separator from "./Separator";
import { ImageCDN } from "./ImageCDN";
import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";
import { useTranslation } from "react-i18next";

function StructurePreview({ uuid }) {
  const { t } = useTranslation("common");
  const [structurePreview, setStructurePreview] = useState({
    image1: "",
    image2: "",
    description: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (uuid) fetchStructurePreview();
  }, [t]);

  const fetchStructurePreview = async () => {
    setIsLoading(true);
    webService.getStructurePreview(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setStructurePreview(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center py-4">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {structurePreview.image1 && structurePreview.image2 && (
            <div className="flex flex-col items-center mx-auto w-full">
              <h1 className="text-4xl font-semibold tracking-wide sm:tracking-widest text-gray-600 uppercase text-center">
                {t("structure_preview.title")}
              </h1>
              <Separator />
              <div className="flex flex-row max-w-7xl w-full mb-20  mx-auto">
                <div className="flex flex-col lg:flex-row relative justify-center items-center">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="bg-white overflow-hidden">
                      <img
                        src={ImageCDN(structurePreview.image1)}
                        className="opacity-100 sm:opacity-50 scale-100 hover:scale-150 transition duration-150 w-full h-full object-cover"
                        alt="structure-img-1"
                        loading="lazy"
                      />
                    </div>
                    <div className="bg-white overflow-hidden">
                      <img
                        src={ImageCDN(structurePreview.image2)}
                        className="opacity-100 sm:opacity-50 scale-100 hover:scale-150 hover:!opacity-75 transition duration-150 w-full h-full object-cover"
                        alt="structure-img-2"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  <article class="max-w-[80%] prose prose-md lg:absolute mx-auto bg-white p-5 text-center sm:text-left">
                    <p>{structurePreview.description_obj.text}</p>
                  </article>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default StructurePreview;
