import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import SideMenu from "../../components/SideMenu";
import { useNavigate } from "react-router-dom";
import webService from "../../services/web.service";
import { useTranslation } from "react-i18next";
import Advisor from "../../components/Advisor";
import { tcolors } from "../../config/Colors";
import { css } from "glamor";
import { STRUCTURE_NAME } from "../../config/backendConfig";
import LoadingIndicator from "../../components/LoadingIndicator";

function Services({ uuid, color }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const [services, setServices] = React.useState([]);
  const { t } = useTranslation("common");

  let navigate = useNavigate();

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  React.useEffect(() => {
    document.title = t("titles.services", { structure_name: STRUCTURE_NAME });
    if (uuid) fetchServices();
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
        }
      },
      (error) => {}
    );
  };

  const fetchServices = async () => {
    setIsLoading(true);
    webService.getServices(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setServices(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };

  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("services.title")}
        </div>
      </div>

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="w-full lg:max-w-4xl mx-auto flex flex-col justify-center gap-12 py-20">
            {services?.map((service, index) => (
              <div
                className="flex flex-col lg:flex-row gap-10"
                key={service.id}
              >
                <img
                  src={service.image}
                  alt={"service-" + index}
                  className="rounded-full w-32 h-32 object-cover hover:scale-105 transition ease-out"
                />
                <div className="flex flex-col gap-4">
                  <h3 className="text-2xl font-semibold">
                    {service.title_obj.text}
                  </h3>
                  <p>{service.description_obj.text}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy />
      <ButtonBar color={config.color} />
    </>
  );
}

export default Services;
