import React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useParams } from "react-router-dom";
import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";
function RoomCarousel() {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(true);
  const [roomImages, setRoomImages] = React.useState([]);

  React.useEffect(() => {
    if (id) fetchImages();
  }, []);

  const fetchImages = async () => {
    setIsLoading(true);
    webService.getImages(id).then(
      (result) => {
        const status = result.status;
        if (status === 200) setRoomImages(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full flex items-center justify-center text-center mx-auto py-4">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={roomImages}
            plugins={[Slideshow, Thumbnails, Zoom]}
          />
          <div className="flex overflow-x-auto gap-3">
            {roomImages?.map((image, index) => (
              <img
                src={image.src}
                className="rounded-lg hover:brightness-50 transition ease-out cursor-pointer h-64"
                onClick={() => setOpen(true)}
                alt={"room-pic-" + index}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default RoomCarousel;
