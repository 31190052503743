import { Carousel } from "flowbite-react";
import React from "react";
import { ImageCDN } from "./ImageCDN";

function ImageCarousel({ images }) {
  return (
    <div className="h-full w-full z-10">
      <Carousel
        slideInterval={5000}
        indicators={false}
        leftControl={<></>}
        rightControl={<></>}
        className="!rounded-none"
      >
        {images?.map((image, index) => (
          <img
            alt={image.alt_image}
            src={ImageCDN(image.image_url)}
            key={"carousel-img-" + image.alt_image}
            className="block w-full h-full object-cover z-10"
          />
        ))}
      </Carousel>
    </div>
  );
}

export default ImageCarousel;
