import React from "react";

function VideoHome({ uuid }) {
  const [link, setLink] = React.useState(
    "https://www.youtube.com/embed/tPjWrfZqPL0"
  );
  return (
    <div className="h-full w-full">
      <iframe
        width="100%"
        height="100%"
        src={
          link +
          "?version=3&mute=1&autoplay=1&showinfo=0&modestbranding=1&loop=1&fs=0&controls=0&playlist=tPjWrfZqPL0"
        }
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        className="ytplayer"
      ></iframe>
    </div>
  );
}

export default VideoHome;
