import { isMobile } from "react-device-detect";
import { IS_CDN, STATIC_URL } from "../config/backendConfig";

export function ImageCDN(url, custom) {
  const mobile_res = "600x400/";
  const desktop_res = "1920x1080/";
  const format = "filters:format(webp)/";

  const res = custom ? custom + "/" : isMobile ? mobile_res : desktop_res;

  return IS_CDN ? STATIC_URL + res + format + url : url;
}
