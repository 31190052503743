import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import GalleryGrid from "../../components/GalleryGrid";
import SideMenu from "../../components/SideMenu";
import webService from "../../services/web.service";
import { useTranslation } from "react-i18next";

function Gallery({ uuid }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setConfig(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };
  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("gallery.title")}
        </div>
      </div>

      <div className="max-w-7xl mx-auto my-24 px-10 lg:px-0">
        <GalleryGrid uuid={uuid} color={config.color} />
      </div>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy uuid={uuid} color={config.color} />
      <ButtonBar uuid={uuid} color={config.color} />
    </>
  );
}

export default Gallery;
