import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { tcolors } from "../config/Colors";
import { css } from "glamor";
import webService from "../services/web.service";
import LanguageSelect from "./LanguageSelect";
import { useTranslation } from "react-i18next";

function SideMenu({ uuid, color, language, setLanguage }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (uuid) fetchPages();
  }, [t]);

  const fetchPages = async () => {
    webService.getPages(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setPages(result.data);
      },
      (error) => {}
    );
  };

  return (
    <>
      <div
        className={`fixed top-0 
        right-0 h-24 w-48 rounded-bl-xl flex items-center 
        justify-center cursor-pointer 
        z-30`}
        {...css({
          backgroundColor: tcolors[color + "-500"],
          ":hover": { backgroundColor: tcolors[color + "-600"] },
        })}
      >
        <div className="flex flex-row items-center">
          <LanguageSelect color={color} />
          <div
            className="hover:scale-90 transition duration-150
          h-full w-full flex flex-col gap-1 items-center justify-center"
            onClick={() => setIsOpen(true)}
          >
            <MenuOutlined className="text-white text-3xl" />
            <span className="uppercase text-white text-sm font-semibold">
              menu
            </span>
          </div>
        </div>
      </div>
      <main
        className={
          " fixed overflow-hidden z-40 bg-gray-900 bg-opacity-70 inset-0 transform ease-in-out" +
          (isOpen
            ? " transition-opacity opacity-100 duration-500 translate-x-0  "
            : " transition-all delay-500 opacity-0 translate-x-full  ")
        }
      >
        <section
          className={
            " w-full sm:w-screen max-w-lg right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
            (isOpen ? " translate-x-0 " : " translate-x-full ")
          }
        >
          <article className="relative w-full sm:w-screen max-w-lg pb-10 flex flex-col space-y-6 overflow-y-auto h-full">
            <div className="flex flex-col p-3">
              <header className="p-4 ml-auto">
                <CloseOutlined
                  className={`text-xl hover:scale-90 transition duration-150 hover:text-${color}-600`}
                  onClick={() => setIsOpen(false)}
                />
              </header>

              <div className="flex flex-col gap-2">
                {pages?.map((page, index) => (
                  <Link
                    key={"link-" + index}
                    to={page.url}
                    className={`text-2xl transition duration-150 mb-2 `}
                    {...css({
                      ":hover": { color: tcolors[color + "-600"] },
                    })}
                  >
                    {page.name_obj.text}
                  </Link>
                ))}
              </div>
            </div>
          </article>
        </section>
        <section
          className=" w-screen xs:w-full h-full cursor-pointer "
          onClick={() => {
            setIsOpen(false);
          }}
        ></section>
      </main>
    </>
  );
}

export default SideMenu;
