import { css } from "glamor";
import React from "react";
import { tcolors } from "../config/Colors";
import { useTranslation } from "react-i18next";

function LanguageSelect({ color }) {
  const [languageVal, setLanguageVal] = React.useState(
    localStorage.getItem("lang")
      ? localStorage.getItem("lang").toLowerCase()
      : "IT"
  );

  const [t, i18n] = useTranslation("common");

  React.useEffect(() => {}, []);

  return (
    <select
      className="p-0 mr-3 text-white border-none language-select font-semibold"
      {...css({
        outline: "none",
        border: "none",
        backgroundColor: "inherit",
      })}
      value={languageVal}
      onChange={(e) => {
        setLanguageVal(e.target.value);
        i18n.changeLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value.toUpperCase());
      }}
    >
      <option
        value="it"
        {...css({
          backgroundColor: tcolors[color + "-600"],
          color: "#FFF",
          fontWeight: "bold",
        })}
      >
        IT
      </option>
      <option
        value="en"
        {...css({
          backgroundColor: tcolors[color + "-600"],
          color: "#FFF",
          fontWeight: "bold",
        })}
      >
        EN
      </option>
    </select>
  );
}

export default LanguageSelect;
