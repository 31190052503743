import React from "react";
import Banner from "../../components/Banner";
import { ImageCDN } from "../../components/ImageCDN";
import SideMenu from "../../components/SideMenu";

import {
  ArrowRightOutlined,
  FullscreenOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { Button, Divider } from "antd";
import LinesEllipsis from "react-lines-ellipsis";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import ButtonBar from "../../components/ButtonBar";
import webService from "../../services/web.service";
import { css } from "glamor";
import { tcolors } from "../../config/Colors";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/LoadingIndicator";

function Rooms({ uuid }) {
  const { t } = useTranslation("common");

  const [rooms, setRooms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    if (uuid) {
      fetchConfig();
    }
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setConfig(result.data);
        setIsLoading(false);
        fetchRooms();
      },
      (error) => {}
    );
  };

  const fetchRooms = async () => {
    setIsLoading(true);
    webService.getRooms(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setRooms(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  const navigate = useNavigate();

  const SingleRoom = ({ room }) => (
    <div
      className="relative h-[28rem] w-80 sm:w-96 flex flex-col 
      rounded-xl shadow-lg shadow-gray-300"
    >
      <img
        src={ImageCDN(room.category.image, "600x400")}
        alt="image-room"
        className="object-cover h-40 rounded-t-xl"
        loading="lazy"
      />
      <div className="flex flex-col sm:flex-row items-center gap-2">
        <h3 className="text-gray-700 font-semibold py-3 px-2 text-2xl w-full sm:w-2/3">
          <LinesEllipsis
            text={room.name}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </h3>
        <div className="w-full sm:w-1/3">
          <div
            className="bg-white rounded-xl shadow-md p-3
            absolute top-10 sm:top-32 right-6 w-36 opacity-90 h-24 "
          >
            <p className="tracking-wide font-semibold">
              {t("rooms_preview.starting_from")}
            </p>
            <h1 className=" text-green-500 text-2xl md:text-3xl font-semibold">
              &euro; {parseFloat(room.price).toFixed(2)}
            </h1>
          </div>
        </div>
      </div>

      <div className="flex flex-row mt-4">
        <Divider style={{ margin: "0" }}>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center gap-3">
              <FullscreenOutlined />
              <p>{room.mq}</p>
            </div>
            <Divider type="vertical" className="bg-gray-300" />
            <div className="flex flex-row items-center gap-3">
              <WifiOutlined />
              <p>Wifi</p>
            </div>
          </div>
        </Divider>
      </div>

      <div className="p-3 text-gray-500">
        <LinesEllipsis
          text={
            room.category.descr
              ? room.category.descr.replace(/(<([^>]+)>)/gi, "")
              : ""
          }
          maxLine="3"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>

      <div className="mt-auto p-3 w-full">
        <div
          className="w-full rounded-lg font-normal flex items-center px-4 cursor-pointer"
          onClick={() => navigate("/room/" + room.id_room)}
          {...css({
            height: "40px",
            fontSize: "18px",
            backgroundColor: tcolors[config.color + "-500"],
            color: "#FFF",
            ":hover": { backgroundColor: tcolors[config.color + "-600"] },
          })}
        >
          <div className=" flex flex-row items-center justify-start w-full gap-2">
            <span className="uppercase tracking-wider font-medium">
              {t("rooms_preview.action")}
            </span>
            <ArrowRightOutlined className="ml-auto" />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("rooms_preview.title_rooms")}
        </div>
      </div>

      <div className="max-w-7xl mx-auto my-24 px-10 lg:px-0">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center text-center mx-auto py-4">
              <LoadingIndicator />
            </div>
          ) : (
            <>
              {rooms?.map((room, index) => (
                <SingleRoom room={room} index={index} />
              ))}
            </>
          )}
        </div>
      </div>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy uuid={uuid} color={config.color} />
      <ButtonBar uuid={uuid} color={config.color} />
    </>
  );
}

export default Rooms;
