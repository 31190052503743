import { PhoneOutlined, ShopOutlined, MailOutlined } from "@ant-design/icons";
import { css } from "glamor";
import React from "react";
import { tcolors } from "../config/Colors";
import webService from "../services/web.service";
import { useTranslation } from "react-i18next";

function ButtonBar({ uuid, color }) {
  const { t } = useTranslation("common");

  const [footerInfo, setFooterInfo] = React.useState({
    reg_phone: "",
    reg_email: "",
  });

  React.useEffect(() => {
    if (uuid) fetchFooterInfo();

    setButtons([
      {
        id: 1,
        text: t("button_bar.button1"),
        icon: <ShopOutlined className="text-white text-2xl" />,
        url: "https://www.idabooking.eu/Home/search_base?uuid=" + uuid,
      },
      {
        id: 2,
        text: t("button_bar.button2"),
        icon: <PhoneOutlined className="text-white text-2xl" />,
        url: "tel:" + footerInfo.reg_phone,
      },
      {
        id: 3,
        text: t("button_bar.button3"),
        icon: <MailOutlined className="text-white text-2xl" />,
        url: "mailto:" + footerInfo.reg_email,
      },
    ]);
  }, [t]);

  const fetchFooterInfo = async () => {
    webService.getButtonBar(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setFooterInfo(result.data[0]);
      },
      (error) => {}
    );
  };

  const [buttons, setButtons] = React.useState([
    {
      id: 1,
      text: t("button_bar.button1"),
      icon: <ShopOutlined className="text-white text-2xl" />,
      url: "https://www.idabooking.eu/Home/search_base?uuid=" + uuid,
    },
    {
      id: 2,
      text: t("button_bar.button2"),
      icon: <PhoneOutlined className="text-white text-2xl" />,
      url: "tel:" + footerInfo.reg_phone,
    },
    {
      id: 3,
      text: t("button_bar.button3"),
      icon: <MailOutlined className="text-white text-2xl" />,
      url: "mailto:" + footerInfo.reg_email,
    },
  ]);
  const CustomButton = (params) => (
    <a
      className="h-full w-full cursor-pointer transition 
      flex-col gap-1"
      {...css({
        backgroundColor: tcolors[color + "-500"],
        ":hover": { backgroundColor: tcolors[color + "-600"] },
      })}
      href={params.button.url}
    >
      <div className="h-full flex flex-col items-center justify-center hover:scale-90 duration-150">
        {params.button.icon}
        <span className="text-white capitalize">{params.button.text}</span>
      </div>
    </a>
  );
  return (
    <div
      className="lg:hidden 
     w-full bottom-0 h-24 sticky z-30"
      {...css({
        backgroundColor: tcolors[color + "-500"],
      })}
    >
      <div className="flex flex-row items-center h-full">
        {buttons?.map((button, index) => (
          <CustomButton button={button} key={"custom-button-" + index} />
        ))}
      </div>
    </div>
  );
}

export default ButtonBar;
