import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import SideMenu from "../../components/SideMenu";
import { useNavigate } from "react-router-dom";
import webService from "../../services/web.service";
import { useTranslation } from "react-i18next";
import Advisor from "../../components/Advisor";
import { tcolors } from "../../config/Colors";
import { css } from "glamor";
import { STRUCTURE_NAME } from "../../config/backendConfig";
import LoadingIndicator from "../../components/LoadingIndicator";

function Restaurant({ uuid, color }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [config, setConfig] = React.useState({});
  const [restaurant, setRestaurant] = React.useState({});
  const { t } = useTranslation("common");

  let navigate = useNavigate();

  React.useEffect(() => {
    if (uuid) fetchConfig();
  }, []);

  React.useEffect(() => {
    document.title = t("titles.restaurant", { structure_name: STRUCTURE_NAME });
    if (uuid) fetchRestaurant();
  }, [t]);

  const fetchConfig = async () => {
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
        }
      },
      (error) => {}
    );
  };

  const fetchRestaurant = async () => {
    setIsLoading(true);
    webService.getRestaurant(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setRestaurant(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };

  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("restaurant.title")}
        </div>
      </div>

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Advisor
            id_advisor="1"
            color={config.color}
            image={"https://www.idaweb.eu/Archive/Media/780/CBFCXUFU12.jpg"}
          />

          <div className="max-w-7xl mx-auto my-24 px-10 lg:px-0">
            <div className="flex flex-col lg:flex-row">
              <div className="flex flex-col gap-2 w-full lg:w-1/2 justify-center px-16">
                <h2 className="text-3xl font-semibold mb-5">
                  {restaurant.title_1_obj.text}
                </h2>
                <p>{restaurant.description_1_obj.text}</p>
                <div
                  className="h-[3px] w-[60px] rounded-lg mt-7"
                  {...css({
                    backgroundColor: tcolors[config.color + "-300"],
                  })}
                />
              </div>
              <div
                className="h-[400px] w-full lg:w-1/2 mt-10 lg:mt-0"
                style={{
                  backgroundImage: 'url("' + restaurant.image_1 + '")',
                }}
              />
            </div>

            <div className="flex flex-col-reverse mt-10 lg:mt-0 lg:flex-row">
              <div
                className="h-[400px] w-full lg:w-1/2 mt-10 lg:mt-0"
                style={{
                  backgroundImage: 'url("' + restaurant.image_2 + '")',
                }}
              />
              <div className="flex flex-col gap-2  w-full lg:w-1/2 justify-center px-16">
                <h2 className="text-3xl font-semibold mb-5">
                  {restaurant.title_2_obj.text}
                </h2>
                <p>{restaurant.description_2_obj.text}</p>
                <div
                  className="h-[3px] w-[60px] rounded-lg mt-7"
                  {...css({
                    backgroundColor: tcolors[config.color + "-300"],
                  })}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy />
      <ButtonBar color={config.color} />
    </>
  );
}

export default Restaurant;
