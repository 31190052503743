import React from "react";
import { Button, Modal, Result } from "antd";

function ErrorModal(props) {
  return (
    <>
      <Modal footer={null} open={props.open} closable={false} centered>
        <Result
          status="error"
          title={props.title}
          subTitle={props.subtitle}
          extra={[
            <Button
              type="primary"
              onClick={() => props.setOpen(false)}
              key="error-extra-1"
            >
              Ok
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
}

export default ErrorModal;
