import React from "react";

function FooterMadeBy() {
  return (
    <div className="bg-white text-gray-700 py-1 px-6 text-center">
      <p className="underline text-sm">
        Creato da{" "}
        <a href="https://www.idaitalia.com" target="_blank" rel="noreferrer">
          F451 S.R.L.
        </a>
      </p>
    </div>
  );
}

export default FooterMadeBy;
