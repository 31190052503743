import React from "react";
import Masonry from "react-masonry-css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import LoadingIndicator from "./LoadingIndicator";
import webService from "../services/web.service";

function GalleryGrid({ uuid, color }) {
  const [images, setImages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (uuid) fetchImages();
  }, []);

  const fetchImages = async () => {
    setIsLoading(true);
    webService.getImagesGallery(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          let images_new = [];
          result.data.map((image, index) => {
            images_new.push({ src: "https://www.idaweb.eu" + image.filepath });
          });
          console.log(images_new);
          setImages(images_new);
        }
        setIsLoading(false);
      },
      (error) => {}
    );
  };
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center py-4">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={images}
            plugins={[Slideshow, Thumbnails, Zoom]}
          />

          <Masonry
            breakpointCols={3}
            className="hidden md:flex my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {images?.map((image, index) => (
              <img
                alt={"gallery" - index}
                src={image.src}
                className="rounded-xl mb-5 cursor-pointer hover:scale-105
             hover:shadow-gray-500 ease-out transition shadow-lg shadow-gray-400"
                onClick={() => setOpen(true)}
              />
            ))}
          </Masonry>

          <Masonry
            breakpointCols={1}
            className="flex md:hidden my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {images?.map((image, index) => (
              <img
                alt={"gallery" - index}
                src={image.src}
                className="rounded-xl mb-5 cursor-pointer hover:scale-105
             hover:shadow-gray-500 ease-out transition shadow-lg shadow-gray-400"
                onClick={() => setOpen(true)}
              />
            ))}
          </Masonry>
        </>
      )}
    </>
  );
}

export default GalleryGrid;
