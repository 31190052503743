import { MailOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";
import LoadingModal from "./LoadingModal";
import axios from "axios";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { css } from "glamor";
import { tcolors } from "../config/Colors";
import { useTranslation } from "react-i18next";
import webService from "../services/web.service";

function Newsletter({ uuid, color }) {
  const [email, setEmail] = React.useState("");
  const [confirmedEmail, setConfirmedEmail] = React.useState("");
  const [showLoading, setShowLoading] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const { t } = useTranslation("common");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const subscribeEmail = async () => {
    setShowLoading(true);
    webService.subscribeNewsletter(uuid, { email: email }).then(
      (result) => {
        const status = result.status;
        if (status === 201) {
          setShowSuccess(true);
          setEmail("");
          setConfirmedEmail(email);
        } else {
          setShowError(true);
        }
        setShowLoading(false);
      },
      (error) => {
        setShowError(true);
        setShowLoading(false);
      }
    );
  };

  return (
    <div
      className="max-w-4xl w-full 
        m-3 shadow-md rounded-xl
        flex items-center justify-center mx-auto
        mt-14 flex-col shadow-gray-300"
    >
      <LoadingModal
        open={showLoading}
        setOpen={setShowLoading}
        title={t("newsletter.subscribing")}
      />

      <SuccessModal
        open={showSuccess}
        setOpen={setShowSuccess}
        title={t("newsletter.all_ok")}
        subtitle={
          <>
            {t("newsletter.thank_you")}&nbsp;
            <b>{confirmedEmail}</b>
          </>
        }
      />

      <ErrorModal
        open={showError}
        setOpen={setShowError}
        title={"Oops..."}
        subtitle={t("newsletter.error_occurred")}
      />

      <div
        className="w-full flex justify-center items-center relative h-24 rounded-t-xl mail-subscribe"
        {...css({
          backgroundColor: tcolors[color + "-500"],
          "::before": { backgroundColor: tcolors[color + "-500"] },
        })}
      >
        <MailOutlined className="text-5xl text-white" />
      </div>
      <div className="p-10 w-full bg-white rounded-b-xl gap-3 flex flex-col items-center justify-center">
        <h1 className="text-xl font-bold text-gray-800 text-center">
          {t("newsletter.subscribe_title")}
        </h1>
        <p className="text-gray-500 text-center">
          {t("newsletter.subscribe_body")}
        </p>
        <div className="flex flex-col md:flex-row items-center gap-3 w-full justify-center">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("newsletter.insert_your_email")}
            style={{ fontSize: "16px" }}
          />
          <Button
            type="primary"
            disabled={!isValidEmail(email) ? true : false}
            onClick={subscribeEmail}
          >
            {t("newsletter.subscribe")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
