import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faBoxTissue,
  faChildren,
  faDice,
  faDog,
  faDumbbell,
  faFire,
  faGamepad,
  faHandsBubbles,
  faHotTub,
  faMale,
  faMartiniGlass,
  faMattressPillow,
  faMountainSun,
  faMugHot,
  faMugSaucer,
  faPaw,
  faPersonSwimming,
  faPumpSoap,
  faSatelliteDish,
  faShower,
  faSocks,
  faSpa,
  faSquareParking,
  faTableTennis,
  faTemperatureArrowDown,
  faTv,
  faVest,
  faWaveSquare,
  faWifi,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import { faSprayCanSparkles } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { css } from "glamor";
import { tcolors } from "../config/Colors";
import { useTranslation } from "react-i18next";

const ServiceRoom = ({ index, color, onlyIcon }) => {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-row items-center gap-1">
      {index === 0 ? (
        <>
          <FontAwesomeIcon
            icon={faBed}
            className="text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.lenzuola")}
            </span>
          )}
        </>
      ) : index === 1 ? (
        <>
          <FontAwesomeIcon
            icon={faBoxTissue}
            className="text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.asciugamani")}
            </span>
          )}
        </>
      ) : index === 2 ? (
        <>
          <FontAwesomeIcon
            icon={faWind}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.asciugacapelli")}
            </span>
          )}
        </>
      ) : index === 3 ? (
        <>
          <FontAwesomeIcon
            icon={faTv}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.tv")}
            </span>
          )}
        </>
      ) : index === 4 ? (
        <>
          <FontAwesomeIcon
            icon={faMartiniGlass}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.minibar")}
            </span>
          )}
        </>
      ) : index === 5 ? (
        <>
          <FontAwesomeIcon
            icon={faMugSaucer}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.bollitore_elettrico")}
            </span>
          )}
        </>
      ) : index === 6 ? (
        <>
          <FontAwesomeIcon
            icon={faWifi}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.wifi")}
            </span>
          )}
        </>
      ) : index === 7 ? (
        <>
          <FontAwesomeIcon
            icon={faSatelliteDish}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.canali_satellitari")}
            </span>
          )}
        </>
      ) : index === 8 ? (
        <>
          <FontAwesomeIcon
            icon={faMattressPillow}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.cuscino_ipoallergenico")}
            </span>
          )}
        </>
      ) : index === 9 ? (
        <>
          <FontAwesomeIcon
            icon={faChildren}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.culla")}
            </span>
          )}
        </>
      ) : index === 10 ? (
        <>
          <FontAwesomeIcon
            icon={faHandsBubbles}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.lavatrice")}
            </span>
          )}
        </>
      ) : index === 11 ? (
        <>
          <FontAwesomeIcon
            icon={faSocks}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.asciugatrice")}
            </span>
          )}
        </>
      ) : index === 12 ? (
        <>
          <FontAwesomeIcon
            icon={faPumpSoap}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.lavastoviglie")}
            </span>
          )}
        </>
      ) : index === 13 ? (
        <>
          <FontAwesomeIcon
            icon={faWaveSquare}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.forno_microonde")}
            </span>
          )}
        </>
      ) : index === 14 ? (
        <>
          <FontAwesomeIcon
            icon={faMugHot}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.macchina_caffe")}
            </span>
          )}
        </>
      ) : index === 15 ? (
        <>
          <FontAwesomeIcon
            icon={faBath}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.vasca")}
            </span>
          )}
        </>
      ) : index === 16 ? (
        <>
          <FontAwesomeIcon
            icon={faShower}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.doccia")}
            </span>
          )}
        </>
      ) : index === 17 ? (
        <>
          <FontAwesomeIcon
            icon={faVest}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.ferro_stiro")}
            </span>
          )}
        </>
      ) : index === 18 ? (
        <>
          <FontAwesomeIcon
            icon={faDog}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.kit_animali")}
            </span>
          )}
        </>
      ) : index === 19 ? (
        <>
          <FontAwesomeIcon
            icon={faGamepad}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.Playstation")}
            </span>
          )}
        </>
      ) : index === 20 ? (
        <>
          <FontAwesomeIcon
            icon={faMountainSun}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.camera_con_vista")}
            </span>
          )}
        </>
      ) : index === 21 ? (
        <>
          <FontAwesomeIcon
            icon={faPersonSwimming}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.piscina")}
            </span>
          )}
        </>
      ) : index === 22 ? (
        <>
          <FontAwesomeIcon
            icon={faDumbbell}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.palestra")}
            </span>
          )}
        </>
      ) : index === 23 ? (
        <>
          <FontAwesomeIcon
            icon={faTableTennis}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.tennis")}
            </span>
          )}
        </>
      ) : index === 24 ? (
        <>
          <FontAwesomeIcon
            icon={faSpa}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.idromassaggio")}
            </span>
          )}
        </>
      ) : index === 25 ? (
        <>
          <FontAwesomeIcon
            icon={faSquareParking}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.parcheggio")}
            </span>
          )}
        </>
      ) : index === 26 ? (
        <>
          <FontAwesomeIcon
            icon={faFire}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.camino")}
            </span>
          )}
        </>
      ) : index === 27 ? (
        <>
          <FontAwesomeIcon
            icon={faTemperatureArrowDown}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.condizionatore")}
            </span>
          )}
        </>
      ) : index === 28 ? (
        <>
          <FontAwesomeIcon
            icon={faDice}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.area_giochi")}
            </span>
          )}
        </>
      ) : index === 29 ? (
        <>
          <FontAwesomeIcon
            icon={faPaw}
            className=" text-xl"
            {...css({
              color: tcolors[color + "-600"],
            })}
          />
          {!onlyIcon && (
            <span className="whitespace-nowrap">
              {t("room.service_list.animali_ammessi")}
            </span>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ServiceRoom;
