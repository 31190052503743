import { css } from "glamor";
import { tcolors } from "../../config/Colors";
import ProductCart from "./ProductCart";
import React from "react";
import { toast } from "react-hot-toast";
import webService from "../../services/web.service";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";

const products = [
  {
    id: 1,
    name: "Salame locale",
    href: "#",
    price: 8,
    imageSrc: "https://www.idaweb.eu/Archive/Media/150/X1K849PGQJ.jpg",
    imageAlt:
      "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
  },
  {
    id: 2,
    name: "Vino In Bottiglia",
    href: "#",
    price: 35,
    imageSrc: "https://www.idaweb.eu/Archive/Media/150/ZBM9MNFFYE.jpg",
    imageAlt:
      "Olive drab green insulated bottle with flared screw lid and flat top.",
  },
  {
    id: 3,
    name: "Marmellata ai frutti rossi",
    href: "#",
    price: 8,
    imageSrc: "https://www.idaweb.eu/Archive/Media/150/XSDI8XIUDS.jpg",
    imageAlt:
      "Person using a pen to cross a task off a productivity paper card.",
  },
  {
    id: 4,
    name: "Pasta fatta in casa",
    href: "#",
    price: 7,
    imageSrc: "https://www.idaweb.eu/Archive/Media/150/KTK96JMI7J.jpg",
    imageAlt:
      "Hand holding black machined steel mechanical pencil with brass tip and top.",
  },
  // More products...
];

export default function ProductsGrid({ config, uuid }) {
  const addProductCart = (product) => {
    setOpenCart(true);
    const productCart = cart.filter((el) => el.id === product.id)[0];
    if (productCart) {
      setCart(
        cart.map((el) => {
          if (el.id === productCart.id) {
            el.quantity++;
            el.price = el.price * el.quantity;
          }
          return el;
        })
      );
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
    toast.success("Il prodotto è stato aggiunto al carrello.");
  };
  const [openCart, setOpenCart] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [cart, setCart] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (uuid) fetchProducts();
  }, [t]);

  const fetchProducts = async () => {
    setIsLoading(true);
    webService.getShop(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setProducts(result.data);
          setIsLoading(false);
        }
      },
      (error) => {}
    );
  };
  return (
    <div className="bg-white">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="w-full items-center justify-center flex">
            <div
              className="flex flex-row gap-3 cursor-pointer
           transition text-white font-semibold rounded-full p-3"
              {...css({
                backgroundColor: tcolors[config.color + "-600"],
                ":hover": {
                  backgroundColor: tcolors[config.color + "-800"],
                },
              })}
              onClick={() => setOpenCart(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                />
              </svg>
              <span>Il tuo carrello</span>
            </div>
          </div>

          <ProductCart
            open={openCart}
            setOpen={setOpenCart}
            cart={cart}
            setCart={setCart}
          />

          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {products.map((product) => (
                <a
                  key={product.id_shop}
                  className="flex flex-col border border-slate-300 rounded-lg
              p-5"
                >
                  <img
                    src={product.image}
                    alt={"product-" + product.id_shop}
                    className="w-full h-[200px] object-contain object-center group-hover:opacity-75"
                  />
                  <h3
                    className="mt-4 text-lg"
                    {...css({
                      color: tcolors[config.color + "-600"],
                    })}
                  >
                    {product.product_name_obj.text}
                  </h3>
                  <p className="mt-1 text-lg font-medium text-gray-900">
                    {parseFloat(product.price).toFixed(2)} &euro;
                  </p>
                  <span
                    className="flex flex-row gap-1 items-center 
                justify-center mt-3 p-2 rounded-md transition
                cursor-pointer ease-out hover:scale-105"
                    onClick={() => addProductCart(product)}
                    {...css({
                      backgroundColor: tcolors[config.color + "-50"],
                      color: tcolors[config.color + "-800"],
                      ":hover": {
                        backgroundColor: tcolors[config.color + "-100"],
                      },
                    })}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                      />
                    </svg>

                    <span className="font-medium">Acquista</span>
                  </span>
                </a>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
